// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");

// import jQuery from "jquery";
var jQuery = require("jquery");
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

require("bootstrap");
require("chart.js");

import flatpickr from "flatpickr";
const ja = require("flatpickr/dist/l10n/ja.js").default.ja;

$(function () {
  flatpickr(".datepicker", {
    locale: ja,
    dateFormat: "Y/m/d",
    disableMobile: "true",
  });

  flatpickr(".timepicker", {
    locale: ja,
    enableTime: true,
    noCalendar: true,
    dateFormat: "H:i",
    disableMobile: "true",
  });
});
require("./paper-dashboard.min");
